// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArTlfLFM0NhadCUVVF2H{
  fill:white;
  transform-origin: center center;
  transform: scale(0.4 , 0.4);
  z-index:9999;
  align-items: center;
  justify-items: center;
  grid-row-start: 3;
  display: flex;
  flex-direction: column;
}
.wUPSOjpSzUo08cthHsOS{
  font-size : 3em;
  user-select: none;
  text-align:center;
}
.Dke1I8hmQztCMdEUSbuZ{
  transform: none;
  user-select: none;
  justify-self: center;
  align-self: center;
  background-color: none;
}`, "",{"version":3,"sources":["webpack://./client/components/resetButton/ResetButton.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,+BAA+B;EAC/B,2BAA2B;EAC3B,YAAY;EACZ,mBAAmB;EACnB,qBAAqB;EACrB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".resetButton{\n  fill:white;\n  transform-origin: center center;\n  transform: scale(0.4 , 0.4);\n  z-index:9999;\n  align-items: center;\n  justify-items: center;\n  grid-row-start: 3;\n  display: flex;\n  flex-direction: column;\n}\n.resetText{\n  font-size : 3em;\n  user-select: none;\n  text-align:center;\n}\n.button{\n  transform: none;\n  user-select: none;\n  justify-self: center;\n  align-self: center;\n  background-color: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetButton": `ArTlfLFM0NhadCUVVF2H`,
	"resetText": `wUPSOjpSzUo08cthHsOS`,
	"button": `Dke1I8hmQztCMdEUSbuZ`
};
export default ___CSS_LOADER_EXPORT___;
