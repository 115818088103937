// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stUpBQfN9bXbJ4AFeoaA{
  justify-items: center;
  align-items: center;
  position:relative;
  display: contents;
}
.stUpBQfN9bXbJ4AFeoaA > * {
  grid-column-start: 1;
  grid-row-start: 1;
  position:relative;
}
.t9Kx4qAyQ851mCrQmQ5R{
  grid-row-start: 2;
  display: flex; /* shrink to fit */
  width: 100%;           /* whatever width you like */
  position: relative;
  justify-content: center;    /* so .content can use position: absolute */
}
.A9h3lEr0GJ2a1qv05ljo{
  position:absolute;
}
.wKAJmSp_Oj22WfKrn4kO{
  position:absolute;
  top:100px;
  display: contents;
}
.UDtXIJbj0K1PhkWuh9bW{
  display: contents;
}`, "",{"version":3,"sources":["webpack://./client/components/App/App.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,aAAa,EAAE,kBAAkB;EACjC,WAAW,YAAY,4BAA4B;EACnD,kBAAkB;EAClB,uBAAuB,KAAK,2CAA2C;AACzE;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,SAAS;EACT,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".container{\n  justify-items: center;\n  align-items: center;\n  position:relative;\n  display: contents;\n}\n.container > * {\n  grid-column-start: 1;\n  grid-row-start: 1;\n  position:relative;\n}\n.appWrapper{\n  grid-row-start: 2;\n  display: flex; /* shrink to fit */\n  width: 100%;           /* whatever width you like */\n  position: relative;\n  justify-content: center;    /* so .content can use position: absolute */\n}\n.content{\n  position:absolute;\n}\n.ResetButton{\n  position:absolute;\n  top:100px;\n  display: contents;\n}\n.InfoBox{\n  display: contents;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `stUpBQfN9bXbJ4AFeoaA`,
	"appWrapper": `t9Kx4qAyQ851mCrQmQ5R`,
	"content": `A9h3lEr0GJ2a1qv05ljo`,
	"ResetButton": `wKAJmSp_Oj22WfKrn4kO`,
	"InfoBox": `UDtXIJbj0K1PhkWuh9bW`
};
export default ___CSS_LOADER_EXPORT___;
