import React, { Component, useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import style from './ResetButton.module.css';
import history from '../../history';

function ResetButton(props) {
  const handleClick = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('startTime');
    window.localStorage.removeItem('currentAge');
    window.localStorage.removeItem('prevTime');
    window.localStorage.removeItem('mushroom');

    history.go('/');
    console.log('removed');
  };
  return (
    <div className={style.resetButton}>
      <button onClick={handleClick}>
        <svg>
          <g>
            <rect
              x='238.5'
              y='170.1'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='238.5'
              y='154.4'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='238.5'
              y='138.7'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='238.5'
              y='123'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='238.5'
              y='107.3'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='222.8'
              y='201.5'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='222.8'
              y='185.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='222.8'
              y='91.6'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='222.8'
              y='75.9'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='207.1'
              y='217.1'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='207.1'
              y='60.2'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='191.4'
              y='232.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='191.4'
              y='44.5'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='175.7'
              y='232.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='175.7'
              y='185.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='175.7'
              y='91.6'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='175.7'
              y='44.5'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='160'
              y='248.5'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='160'
              y='170.1'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='160'
              y='107.3'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect x='160' y='28.8' className='st2' width='15.7' height='15.7' />
            <rect
              x='144.3'
              y='248.5'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='144.3'
              y='154.4'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='144.3'
              y='123'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='144.3'
              y='28.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='128.6'
              y='248.5'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='128.6'
              y='138.7'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='128.6'
              y='28.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='112.9'
              y='248.5'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='112.9'
              y='154.4'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='112.9'
              y='123'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='112.9'
              y='28.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='97.2'
              y='248.5'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='97.2'
              y='170.1'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='97.2'
              y='107.3'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='97.2'
              y='28.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='81.5'
              y='232.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='81.5'
              y='185.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='81.5'
              y='91.6'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='81.5'
              y='44.5'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='65.9'
              y='232.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='65.9'
              y='44.5'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='50.2'
              y='217.1'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='50.2'
              y='60.2'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='34.5'
              y='201.5'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='34.5'
              y='185.8'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='34.5'
              y='91.6'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='34.5'
              y='75.9'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='18.8'
              y='170.1'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='18.8'
              y='154.4'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect
              x='18.8'
              y='138.7'
              className='st2'
              width='15.7'
              height='15.7'
            />
            <rect x='18.8' y='123' className='st2' width='15.7' height='15.7' />
            <rect
              x='18.8'
              y='107.3'
              className='st2'
              width='15.7'
              height='15.7'
            />
          </g>
        </svg>
      </button>
      <div className={style.resetText}>reset</div>
    </div>
  );
}

export default withRouter(ResetButton);
