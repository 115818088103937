// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LrXPD8UNlYQk2X13ZX2i{
  grid-row-start:1;
  height: 100%;
  display: flex;
  align-items: center;
}
.etUEXcM4L3w1F_Gskx6w{
  background-color:white;
  color: black
}
button {
  padding-right:5px;
  padding-left:5px;

}`, "",{"version":3,"sources":["webpack://./client/components/InfoBox/InfoBox.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,sBAAsB;EACtB;AACF;AACA;EACE,iBAAiB;EACjB,gBAAgB;;AAElB","sourcesContent":[".infoBox{\n  grid-row-start:1;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n.selectedSpeedOption{\n  background-color:white;\n  color: black\n}\nbutton {\n  padding-right:5px;\n  padding-left:5px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBox": `LrXPD8UNlYQk2X13ZX2i`,
	"selectedSpeedOption": `etUEXcM4L3w1F_Gskx6w`
};
export default ___CSS_LOADER_EXPORT___;
